import { hasAccess } from '@/mixins/hasAccess.mixin'
import { setCookie } from '@/services/cookies'
import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notification'
import { useSessionStore } from '@/stores/session'
import { titleCase } from '@/utils/text'
import { RouteLocationNormalized } from 'vue-router'
import { ROUTES } from './routes.enum'

/**
 * Router guard that requires an authenticated user
 * @param to - The route being navigated to
 * @returns Redirect object or undefined
 */
const requireAuthenticatedUser = async (to: RouteLocationNormalized) => {
  const authStore = useAuthStore()
  const sessionStore = useSessionStore()
  const notificationStore = useNotificationStore()

  const user = sessionStore.getUser()
  const inviteCompleted = user?.invite_completed ?? true
  const addMfaRequired = user?.add_mfa_required ?? false
  const inviteFlowRoutes = [ROUTES.INVITE_ADD_AUTHENTICATOR, ROUTES.INVITE_ADD_PASSKEY]

  if (authStore.isAuthenticated) {
    if (inviteCompleted) {
      if (inviteFlowRoutes.includes(to.name as ROUTES)) return { name: ROUTES.ORDERS }
    } else if (to.name !== ROUTES.SIGN_OUT) {
      const redirectRoute = addMfaRequired ? ROUTES.INVITE_ADD_AUTHENTICATOR : ROUTES.INVITE_ADD_PASSKEY
      if (to.name !== redirectRoute) return { name: redirectRoute }
    }

    let allowAccess = false

    if (to.meta.roles) {
      const rolesToCheck = Array.isArray(to.meta.roles) ? to.meta.roles : [to.meta.roles]
      allowAccess = hasAccess(rolesToCheck)
    }

    if (!allowAccess && to.meta && to.meta.checkByUser && to.params && to.params.id) {
      const user = sessionStore.getUser()
      allowAccess = to.params.id == user?.id
    }

    if (!allowAccess) {
      notificationStore.notifyError(
        `You don't have permission to view the ${to.meta.displayName || titleCase(to.name as string)} page. Please contact your administrator for access.`,
        true
      )
      return { name: ROUTES.ORDERS }
    }
  } else {
    const authToken = authStore.getToken()
    authStore.clear()

    if (authToken && ((to.name == ROUTES.ORDERS && !to.redirectedFrom) || to.name != ROUTES.ORDERS)) {
      setCookie('signin-expired', 'true')
    }

    // Save the original URL for redirect after sign-in
    // to.fullPath preserves the entire URL including:
    // - path
    // - query parameters
    // - hash fragments (via encoding)
    const currentPath = to.fullPath
    return {
      name: ROUTES.SIGN_IN,
      query: { redirect: currentPath }
    }
  }
}

const redirectIfAuthenticated = (to: RouteLocationNormalized) => {
  const authStore = useAuthStore()
  const notificationStore = useNotificationStore()

  if (authStore.isAuthenticated) {
    if (to.meta.redirectNotification) {
      notificationStore.notifyWarning(to.meta.redirectNotification as string)
    }
    return { name: ROUTES.ORDERS }
  }
}

export { requireAuthenticatedUser, redirectIfAuthenticated }
