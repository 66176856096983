import { ORDER_STATUSES } from '@/utils/constants'

export const capitalizeFirstLetter = (text: string): string => {
  if (text && text.toString().length > 0) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  } else {
    return ''
  }
}

export const pluralize = (quantity: number, word: string) => {
  if (quantity > 1) {
    return `${word}s`
  } else {
    return word
  }
}

export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (err) {
    return false
  }
}

export const getOrderStatusColor = (status: string): string => {
  const defaultColor = 'bg-dashboard-yellow'
  if (!status) return defaultColor

  const colorMap: { [key: string]: string } = {
    [ORDER_STATUSES.COMPLETED]: 'bg-dashboard-success-green',
    [ORDER_STATUSES.REFUNDED]: 'bg-dashboard-action-blue',
    [ORDER_STATUSES.PARTIALLY_REFUNDED]: 'bg-dashboard-action-blue',
    [ORDER_STATUSES.CANCELLED]: 'bg-dashboard-pink',
    [ORDER_STATUSES.UNCAPTURED]: 'bg-dashboard-error-red'
  }

  return colorMap[status] || defaultColor
}

export const humanize = (text: string | null = ''): string => {
  if (!text) return ''

  return text
    .replace(/([a-z])([A-Z])/g, '$1 $2') // add space between words in camelCase
    .replace(/[_-]/g, ' ')
}

export const titleCase = (text: string = ''): string => {
  return humanize(text)
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ')
}

export const formatNumberWithCommas = (string: string): string => {
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatDecimalNumber = (value: string | number, decimalPlaces = 2): string => {
  const stringValue = value.toString()

  const sanitizedValue = stringValue.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')

  const parts = sanitizedValue.split('.')
  if (parts[1]?.length > decimalPlaces) {
    parts[1] = parts[1].substring(0, decimalPlaces)
  }

  return parts.join('.')
}

export const formatMoney = (value: string | number): string => {
  return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(value))
}

export const isValidIpAddress = (ip: string): boolean => {
  // Regular expression for IPv4 validation
  const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/

  if (!ipv4Regex.test(ip)) return false

  // Check if each octet is valid (0-255)
  const octets = ip.split('.')
  return octets.every((octet) => {
    const num = parseInt(octet, 10)
    return num >= 0 && num <= 255
  })
}

export const isValidPort = (port: string): boolean => {
  if (!port) return true // Empty port is valid as it's optional
  const portNumber = parseInt(port, 10)
  return !isNaN(portNumber) && portNumber >= 1024 && portNumber <= 65535
}

export default {
  pluralize,
  capitalizeFirstLetter,
  copyToClipboard,
  getOrderStatusColor,
  formatNumberWithCommas,
  formatDecimalNumber,
  isValidIpAddress,
  isValidPort
}
