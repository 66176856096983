import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useSessionStore } from '@/stores/session'
import { useNotificationStore } from '@/stores/notification'
import { ACTION_ROLES } from '@/utils/constants'
import { redirectIfAuthenticated, requireAuthenticatedUser } from '@/router/guards'
import { ROUTES } from '@/router/routes.enum'
import { productRoutes } from '@/modules/products/products.routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/sign_in',
      name: ROUTES.SIGN_IN,
      beforeEnter: redirectIfAuthenticated,
      component: () => import('@/views/SignInView.vue'),
      meta: {
        redirectNotification: 'You are already signed in'
      }
    },
    {
      path: '/sessions/magic_link/verify',
      name: ROUTES.SEND_MAGIC_LINK,
      beforeEnter: redirectIfAuthenticated,
      component: () => import('@/views/MagicLinkView.vue'),
      meta: {
        redirectNotification: 'Cannot use a magic link when already signed in'
      }
    },
    {
      path: '/invite/set_password',
      name: ROUTES.INVITE_SET_PASSWORD,
      beforeEnter: redirectIfAuthenticated,
      component: () => import('@/views/accept-invite/SetPasswordView.vue'),
      meta: {
        redirectNotification: 'You cannot accept a new user invitation when already signed in'
      }
    },
    {
      path: '/invite/add_authenticator',
      name: ROUTES.INVITE_ADD_AUTHENTICATOR,
      beforeEnter: requireAuthenticatedUser,
      component: () => import('@/views/accept-invite/AddAuthenticatorView.vue'),
      meta: {
        roles: ACTION_ROLES.INVITE_ADD_AUTHENTICATOR
      }
    },
    {
      path: '/invite/add_passkey',
      name: ROUTES.INVITE_ADD_PASSKEY,
      beforeEnter: requireAuthenticatedUser,
      component: () => import('@/views/accept-invite/AddPasskeyView.vue'),
      meta: {
        roles: ACTION_ROLES.INVITE_ADD_PASSKEY
      }
    },
    {
      path: '/user_sessions/auto_sign_in',
      name: ROUTES.AUTO_SIGN_IN,
      beforeEnter: requireAuthenticatedUser,
      component: () => import('@/views/AutoSignInView.vue'),
      meta: {
        roles: ACTION_ROLES.AUTO_SIGN_IN
      }
    },
    {
      path: '/password/reset',
      name: ROUTES.SEND_RESET_PASSWORD_INSTRUCTIONS,
      beforeEnter: redirectIfAuthenticated,
      component: () => import('@/views/SendResetPasswordInstructionsView.vue'),
      meta: {
        redirectNotification: 'Use the Password & Security page to reset your password when already signed in'
      }
    },
    {
      path: '/password/new',
      name: ROUTES.RESET_PASSWORD,
      beforeEnter: redirectIfAuthenticated,
      component: () => import('@/views/ResetPasswordView.vue'),
      meta: {
        redirectNotification: 'Use the Password & Security page to reset your password when already signed in'
      }
    },
    {
      path: '/',
      redirect: '/orders',
      component: () => import('@/views/BaseView.vue'),
      children: [
        ...productRoutes,
        {
          path: '/orders',
          name: ROUTES.ORDERS,
          component: () => import('@/views/OrdersView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.ORDERS_VIEW
          }
        },
        {
          path: '/orders/:id',
          name: ROUTES.ORDER_DETAILS,
          component: () => import('@/views/OrderDetailsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.ORDERS_VIEW
          }
        },
        {
          path: '/customers',
          name: ROUTES.CUSTOMERS,
          component: () => import('@/views/CustomersView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.CUSTOMERS_VIEW
          }
        },
        {
          path: '/customers/:id',
          name: ROUTES.CUSTOMER_DETAILS,
          component: () => import('@/views/CustomerDetailsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.CUSTOMER_DETAILS_VIEW
          }
        },
        {
          path: '/cards',
          name: ROUTES.CARDS,
          component: () => import('@/views/CardsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.CARD_PASSES_VIEW
          }
        },
        {
          path: '/cards/:id',
          name: ROUTES.CARD_DETAILS,
          component: () => import('@/views/CardDetailsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.CARD_PASS_DETAILS_VIEW
          }
        },
        {
          path: '/decrypt',
          name: ROUTES.DECRYPT,
          component: () => import('@/views/DecryptView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.DECRYPT_VIEW
          }
        },
        {
          path: '/passes',
          name: ROUTES.PASSES,
          component: () => import('@/views/PassesView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.MOBILE_PASS_VIEW
          }
        },
        {
          path: '/passes/:id',
          name: ROUTES.PASS_DETAILS,
          component: () => import('@/views/PassDetailsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.MOBILE_PASS_DETAILS_VIEW
          }
        },
        {
          path: '/refunds',
          name: ROUTES.REFUNDS,
          component: () => import('@/views/RefundsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.REFUNDS_VIEW
          }
        },
        {
          path: '/scans',
          name: ROUTES.SCANS,
          component: () => import('@/views/ScansView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.SCANS_VIEW
          }
        },
        {
          path: '/passes/:id/scans',
          name: ROUTES.SCANS_BY_PASS,
          component: () => import('@/views/ScansView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.SCANS_VIEW
          }
        },
        {
          path: '/cards/:card_id/scans',
          name: ROUTES.SCANS_BY_CARD,
          component: () => import('@/views/ScansView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.SCANS_VIEW
          }
        },
        {
          path: '/reversed_scans',
          name: ROUTES.REVERSED_SCANS,
          component: () => import('@/views/ReversedScansView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.REVERSED_SCANS_VIEW
          }
        },
        {
          path: '/passes/:id/reversed_scans',
          name: ROUTES.REVERSED_SCANS_BY_PASS,
          component: () => import('@/views/ReversedScansView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.REVERSED_SCANS_VIEW
          }
        },
        {
          path: '/cards/:card_id/reversed_scans',
          name: ROUTES.REVERSED_SCANS_BY_CARD,
          component: () => import('@/views/ReversedScansView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.REVERSED_SCANS_VIEW
          }
        },
        {
          path: '/users',
          name: ROUTES.USERS,
          component: () => import('@/views/UsersView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.USERS_VIEW
          }
        },
        {
          path: '/users/:id',
          name: ROUTES.USER_DETAILS,
          component: () => import('@/views/UserDetailsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.USER_DETAILS_VIEW,
            checkByUser: true
          }
        },
        {
          path: '/users/:userId/security',
          name: ROUTES.USER_SECURITY,
          component: () => import('@/views/SecurityView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.USER_SECURITY_VIEW
          }
        },
        {
          path: '/security',
          name: ROUTES.SECURITY,
          component: () => import('@/views/SecurityView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.SECURITY_VIEW
          }
        },
        {
          path: '/reports',
          name: ROUTES.REPORTS,
          component: () => import('@/views/ReportsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.REPORTS_VIEW
          }
        },
        {
          path: '/pos',
          name: ROUTES.POS_NEW_ORDER,
          component: () => import('@/modules/pos/PointOfSale.view.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.POS_NEW_ORDER_VIEW,
            fullWidthLayout: true,
            showStatusBadges: true,
            whiteBackground: true,
            collapsibleSidebar: true,
            displayName: 'Point-of-sale'
          }
        },
        {
          path: '/settings',
          name: ROUTES.SETTINGS,
          component: () => import('@/views/SettingsView.vue'),
          redirect: {
            name: ROUTES.SETTINGS_BUSINESS
          },
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.SETTINGS_VIEW
          },
          children: [
            {
              path: 'business',
              name: ROUTES.SETTINGS_BUSINESS,
              component: () => import('@/views/settings/BusinessSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_BUSINESS_VIEW
              }
            },
            {
              path: 'locations',
              name: ROUTES.SETTINGS_LOCATIONS,
              component: () => import('@/views/settings/LocationsSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_LOCATIONS_VIEW
              }
            },
            {
              path: 'salespoints',
              name: ROUTES.SETTINGS_SALESPOINTS,
              component: () => import('@/views/settings/SalespointsSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_SALESPOINTS_VIEW
              }
            },
            {
              path: 'printers',
              name: ROUTES.SETTINGS_PRINTERS,
              component: () => import('@/views/settings/PrintersSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_PRINTERS_VIEW
              }
            },
            {
              path: 'payment-terminals',
              name: ROUTES.SETTINGS_PAYMENT_TERMINALS,
              component: () => import('@/views/settings/PaymentTerminalsSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_PAYMENT_TERMINALS_VIEW
              }
            },
            {
              path: 'vessels',
              name: ROUTES.SETTINGS_VESSELS,
              component: () => import('@/views/settings/VesselsSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_VESSELS_VIEW
              }
            },
            {
              path: 'system',
              name: ROUTES.SETTINGS_SYSTEM,
              component: () => import('@/views/settings/SystemSettingsView.vue'),
              beforeEnter: requireAuthenticatedUser,
              meta: {
                roles: ACTION_ROLES.SETTINGS_SYSTEM_VIEW
              }
            }
          ]
        },
        {
          path: '/sign_out',
          name: ROUTES.SIGN_OUT,
          component: () => import('@/views/SignOutView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.SIGN_OUT
          }
        },
        {
          path: '/qr_codes',
          name: ROUTES.QR_CODES,
          component: () => import('@/views/QrCodesView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.QR_CODES_VIEW
          }
        },
        {
          path: '/validators',
          name: ROUTES.VALIDATORS,
          component: () => import('@/views/ValidatorsView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.VALIDATORS_VIEW
          }
        },
        {
          path: '/orders_test_page',
          name: ROUTES.ORDERS_TEST_PAGE,
          component: () => import('@/views/OrdersTestPageView.vue'),
          beforeEnter: requireAuthenticatedUser,
          meta: {
            roles: ACTION_ROLES.ORDERS_TEST_PAGE_VIEW
          }
        }
      ]
    },
    {
      path: '/404',
      name: ROUTES.NOT_FOUND,
      component: () => import('@/views/NotFoundView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404'
    }
  ]
})

router.beforeEach(async (_to, _from, next) => {
  const authStore = useAuthStore()
  const sessionStore = useSessionStore()
  authStore.loadTokenFromCookie()
  const pingResult = await sessionStore.ping()
  authStore.isAuthenticated = !pingResult.error && !!pingResult.success
  next()
})

router.afterEach(() => {
  const notificationStore = useNotificationStore()
  notificationStore.clearDisplayed()
})

export default router
