import { RouteRecordRaw } from 'vue-router'
import { ROUTES } from '@/router/routes.enum'
import { ACTION_ROLES } from '@/utils/constants'
import { requireAuthenticatedUser } from '@/router/guards'

export const productRoutes: RouteRecordRaw[] = [
  {
    path: '/products',
    name: ROUTES.PRODUCTS,
    component: () => import('@/modules/products/views/ProductsListing.view.vue'),
    beforeEnter: requireAuthenticatedUser,
    meta: {
      roles: ACTION_ROLES.PRODUCTS_VIEW
    }
  },
  {
    path: '/products/new',
    name: ROUTES.PRODUCT_NEW,
    component: () => import('@/modules/products/views/ProductDetail.view.vue'),
    beforeEnter: requireAuthenticatedUser,
    meta: {
      roles: ACTION_ROLES.PRODUCT_CREATE
    }
  },
  {
    path: '/products/:id',
    name: ROUTES.PRODUCT_DETAIL,
    component: () => import('@/modules/products/views/ProductDetail.view.vue'),
    beforeEnter: requireAuthenticatedUser,
    meta: {
      roles: ACTION_ROLES.PRODUCT_DETAILS_VIEW
    }
  }
]
