export const enum ROUTES {
  AUTO_SIGN_IN = 'autoSignIn',
  CARDS = 'cards',
  CARD_DETAILS = 'card_details',
  CONFIGURATION = 'configuration',
  CUSTOMER_DETAILS = 'customer_details',
  CUSTOMERS = 'customers',
  DECRYPT = 'decrypt',
  INVITE_ADD_AUTHENTICATOR = 'invite_add_authenticator',
  INVITE_ADD_PASSKEY = 'invite_add_passkey',
  INVITE_SET_PASSWORD = 'invite_set_password',
  NOT_FOUND = 'not_found',
  ORDER_DETAILS = 'order_details',
  ORDERS = 'orders',
  PASS_DETAILS = 'pass_details',
  PASSES = 'passes',
  POS_NEW_ORDER = 'pos-new-order',
  PRODUCT_DETAIL = 'product_detail',
  PRODUCT_EDIT = 'product_edit',
  PRODUCT_NEW = 'product_new',
  PRODUCTS = 'products',
  QR_CODES = 'qr_codes',
  REPORTS = 'reports',
  RESET_PASSWORD = 'resetPassword',
  SCANS = 'scans',
  SCANS_BY_PASS = 'scans_by_pass',
  SCANS_BY_CARD = 'scans_by_card',
  SECURITY = 'security',
  SEND_MAGIC_LINK = 'sendMagicLink',
  SEND_RESET_PASSWORD_INSTRUCTIONS = 'sendResetPasswordInstructions',
  SETTINGS = 'settings',
  SETTINGS_BUSINESS = 'settings-business',
  SETTINGS_LOCATIONS = 'settings-locations',
  SETTINGS_PAYMENT_TERMINALS = 'settings-payment-terminals',
  SETTINGS_PRINTERS = 'settings-printers',
  SETTINGS_PRODUCT_TYPE_DETAIL = 'settings-product-type-detail',
  SETTINGS_PRODUCT_TYPES = 'settings-product-types',
  SETTINGS_SALESPOINTS = 'settings-salespoints',
  SETTINGS_SYSTEM = 'settings-system',
  SETTINGS_VESSELS = 'settings-vessels',
  SIGN_IN = 'signIn',
  SIGN_OUT = 'signOut',
  USER_DETAILS = 'user_details',
  USER_SECURITY = 'user_security',
  USERS = 'users',
  VALIDATORS = 'validators',
  REVERSED_SCANS = 'reversed-scans',
  REVERSED_SCANS_BY_PASS = 'reversed-scans-by-pass',
  REVERSED_SCANS_BY_CARD = 'reversed-scans-by-card',
  ORDERS_TEST_PAGE = 'orders-test-page',
  REFUNDS = 'refunds'
}
