export interface ApiFilter {
  field: string
  op: string
  value: any
}

export interface FilterValue {
  name: string
  label: string
  type: string
  field: string
  options?: Array<any>
  selected?: boolean
  fieldOptions?: Array<any>
  topOptions?: Array<any>
  defaultOptions?: {
    option: string
    first: number | null
    second?: number | null
  }
  values?: any
  period?: string
  allowOtherOption?: boolean
  multiple?: boolean
}

export interface FilterValues {
  [key: string]: FilterValue
}

export interface NumberRangeValue {
  option: string
  first: number | null
  second?: number | null
  topOption?: {
    label: string
    value: string
    type: string
  }
}

export interface DateSelectorValue {
  option: string
  firstDate: string | null
  secondDate?: string | null
  period?: {
    value: string
    type: string
  }
  selectedField?: string
}

export interface CheckboxListValue {
  selected: Array<{
    value: string
    separate?: boolean
    operand?: string
  }>
}

export interface DropdownSelectorValue {
  value: string
}

export interface SelectWithSearchValue {
  value: Array<string>
}

export interface FilterOption {
  label: string
  value: string
}

export interface Filter {
  name: string
  label: string
  type: FilterType
  field: string
  multiple?: boolean
  options?: FilterOption[] | (() => Promise<FilterOption[]>)
}

export enum FilterType {
  NUMBER_RANGE = 'numberRange',
  DATE_SELECTOR = 'dateSelector',
  CHECKBOX_LIST = 'checkboxList',
  DROPDOWN = 'dropdown',
  SELECT_WITH_SEARCH = 'selectWithSearch'
}
